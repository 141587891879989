import Models from "./Models";
import Collection from "./Collection";

export default class Collections extends Models {

  constructor(models = []) {
    super(Collection, models);
  }

  static async clear() {
    return Models._clear("collections");
  }

  static async count(search = "", offset = 0, limit = 48) {
    return Models._count("collections", search, offset, limit);
  }

  static async restore(search = "", offset = 0, limit = 48) {
    return Models._restore(Collections, Collection, "collections", search, offset, limit);
  }

  static async load(search = "", offset = 0, limit = 48, deleted = false, grade_bands, collection_topic_ids, my_collections, shared_with_me, archived_shared_with_me = false, all_collections = false) {
    let queryParams = [];

    collection_topic_ids && queryParams.push(`collection_topic_ids=${collection_topic_ids}`);
    my_collections && queryParams.push(`my_collections=${my_collections}`);
    all_collections && queryParams.push(`all_collections=${all_collections}`);
    shared_with_me && queryParams.push(`shared_with_me=${shared_with_me}`);
    archived_shared_with_me && queryParams.push(`archived_shared_with_me=${archived_shared_with_me}`);
    grade_bands && queryParams.push(`grade_bands=${grade_bands}`);

    const path = queryParams.length > 0 ? `/collections.json?${queryParams.join("&")}` : "/collections.json";

    return await Models._load(Collections, Collection, path, search, offset, limit, deleted);
  }
}