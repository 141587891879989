<template>
  <div
    v-if="maintenanceMessage"
    class="notification-bar">
    <div>
      {{ maintenanceMessage }}
    </div>
  </div>
  <div
    v-else
    :slug="slugCommit"
    :version="releaseVersion"
    :released="releaseCreatedAt">
    <!-- dialogs -->
    <DynamicDialog
      :pt="{
        root: 'bg-white w-full overflow-hidden',
        header: 'hidden',
        content: 'flex flex-col h-full overflow-hidden',
        footer: 'hidden'
      }" />
    <ConfirmDialog
      :pt="{
        root: 'bg-white rounded-16 max-w-[780px]',
        closeButtonIcon: 'text-black text-[28px]',
        title: 'text-26 font-bold',
        header: 'pl-40 pr-24 pt-24 pb-16',
        content: 'text-16 text-grey-2 pr-40 pl-40 pb-40',
        footer: 'flex gap-12 justify-end p-24 border-t border-grey-3'
      }"
      closeIcon="fa fa-xmark"
      :draggable="false" />
    <!-- replaces the ionic loading controller -->
    <Dialog
      v-model:visible="showAppLoading"
      :showHeader="false"
      :modal="true"
      :pt="{
        root: 'bg-white rounded-16 px-40 py-30',
      }">
      <div class="flex items-center gap-20">
        <loading-spinner
          size="32px"
          style="margin: 0; padding: 0;" />
        <span>
          {{ $t('helpers.loading') }}
        </span>
      </div>
    </Dialog>
    <!-- LTI header -->
    <!-- NOTE: lets move this out of here and make an LTI Layout -->
    <div
      v-if="isLti"
      class="flex items-center justify-between gap-20 px-40 py-20">
      <router-link
        class="home-icon"
        to="/lti">
        <img
          id="wayfinder-logo-black"
          class="h-auto w-[42px]"
          src="@/assets/icon-black.png"
          alt="home" />
      </router-link>
      <profile-menu
        v-if="$route.name === 'LtiList'"
        :mobile="true">
      </profile-menu>
    </div>
    <!-- sidebars -->
    <template v-if="user">
      <profile-sidebar />
      <announcement-sidebar />
    </template>
    <!-- toasts -->
    <Toast
      position="top-right"
      group="main"
      :closable="false" />
    <Toast
      v-if="!user?.isStudent()"
      position="bottom-center"
      group="mobile"
      :closable="true" />
    <!-- content -->
    <div id="app-content">
      <!-- splashes -->
      <splashes v-if="user && !ltiRoute && !codeRoute" />
      <!-- router view -->
      <router-view v-if="initialized" />
    </div>
    <!-- impersonation indicator -->
    <template v-if="user?.impersonating">
      <div class="impersonate-user-outline"></div>
      <div class="impersonate-indicator">
        <div class="impersonate-user-name">
          {{ user.name }}
          <a
            href="/home/?stop_impersonating=1"
            alt="Undo"
            aria-label="Undo">
            <fa-icon
              icon="arrow-rotate-left"
              size="md"
              color="white">
            </fa-icon>
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="js">
import {
  defineComponent
} from "vue";
import {
  mapGetters
} from "vuex";

import * as Sentry from "@sentry/vue";
import {
  Integrations
} from "@sentry/tracing";

import Dialog from "primevue/dialog";
import ConfirmDialog from "primevue/confirmdialog";
import DynamicDialog from "primevue/dynamicdialog";
import Toast from "primevue/toast";

import {
  storageKey
} from "@/configKeys";
import store from "@/store";

import Helpers from "./mixins/Helpers";
import Splashes from "@/components/notifications/Splashes";
import ProfileMenu from "@/components/menus/ProfileMenu";
import ProfileSidebar from "@/components/sidebars/ProfileSidebar";
import AnnouncementSidebar from "@/components/sidebars/AnnouncementSidebar";
import LoadingSpinner from "@/components/utils/LoadingSpinner";
import FaIcon from "@/components/utils/FaIcon";
import { Userpilot } from "userpilot";

export default defineComponent({
  name: "App",
  components: {
    Dialog,
    ConfirmDialog,
    DynamicDialog,
    Toast,
    Splashes,
    ProfileMenu,
    ProfileSidebar,
    AnnouncementSidebar,
    LoadingSpinner,
    FaIcon
  },
  mixins: [
    Helpers
  ],
  data() {
    return {
      initialized: false
    };
  },
  computed: {
    ...mapGetters([
      "activeRole",
      "appLoading"
    ]),
    showAppLoading() {
      return this.appLoading !== false;
    },
    maintenanceMessage() {
      return process.env.VUE_APP_MAINTENANCE_NOTICE || "";
    },
    slugCommit() {
      return process.env.HEROKU_SLUG_COMMIT || "";
    },
    releaseVersion() {
      return process.env.HEROKU_RELEASE_VERSION || "";
    },
    releaseCreatedAt() {
      return process.env.HEROKU_RELEASE_CREATED_AT || "";
    },
    shouldSetupHotjar() {
      return process.env.VUE_APP_HOTJAR_ID && process.env.VUE_APP_HOTJAR_ID.length > 0 && this.user && !this.user.district.prefs_no_third_party;
    },
    shouldSetupSentry() {
      return this.user && process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_SENTRY_DSN.length > 0 && !this.user.district.prefs_no_third_party;
    },
    shouldSetupUserPilot() {
      return process.env.VUE_APP_USERPILOT_CODE && process.env.VUE_APP_USERPILOT_CODE.length > 0 && this.user && !this.user.district.prefs_no_third_party;
    },
    shouldSetupVitally() {
      return this.user && this.user.district.sf_id && this.user.isEducator() && !this.user.district.demo_type && process.env.VUE_APP_VITALLY_API_KEY && process.env.VUE_APP_VITALLY_API_KEY.length > 0 && !this.user.district.prefs_no_third_party;
    },
    shouldSetupZendesk() {
      return this.user && !this.user.district.prefs_no_third_party && !this.user.isManager() && this.user.isEducator();
    },
    shouldSetupZonka() {
      let random = Math.floor((Math.random() * 10) + 1); // only potentially show 1 out of 10 times
      return random === 1 &&
        process.env.VUE_APP_ZONKA_SETTINGS_URL &&
        process.env.VUE_APP_ZONKA_SETTINGS_URL.length > 0 &&
        process.env.VUE_APP_ZONKA_URL &&
        process.env.VUE_APP_ZONKA_URL.length > 0 &&
        this.user && this.user.sign_in_count > 3 &&
        this.user.district.prefs_no_third_party !== undefined && !this.user.district.prefs_no_third_party &&
        this.user.isEducator();
    },
    shouldSetupAccessibe() {
      return this.user && !this.user.district.prefs_no_third_party && this.user?.accessibility_tools && process.env.VUE_APP_APP_ENV === "production";
    },
    ltiRoute() {
      return this.$route.meta.lti;
    },
    codeRoute() {
      return this.$route.meta.qrcode;
    }
  },
  watch: {
    async $route(toRoute, fromRoute) {
      if (fromRoute.name === "UserLogin") {
        this.initialized = false;
        await this.initializeApp();
      }
    }
  },
  async beforeMount() {
    for (let key in localStorage) {
      if (key.includes("wayfinder_v") && key !== storageKey) {
        localStorage.removeItem(key);
      }
    }

    this.initializeApp();
  },
  mounted() {
    /* Checking for screen width on load */
    if (screen.width < 768 && this.$route.meta.qrcode !== true) {
      this.$toast.add({
        severity: "warn",
        group: "mobile",
        summary: this.$t("screen_too_small.title"),
        detail: this.$t("screen_too_small.description")
      });
    }
  },
  beforeUnmount() {
    this.hideLoading();
  },
  methods: {
    async initializeApp() {
      this.redirectOldDomain(window.location.href);
      try {
        await this.$store.dispatch("userRestore");
        if (this.user) {
          this.$i18n.locale = this.user.locale;
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const impersonate = urlParams.get("impersonate");
          const stopImpersonating = urlParams.get("stop_impersonating");
          if (impersonate) {
            await this.$store.dispatch("impersonate", impersonate);
          }
          if (stopImpersonating) {
            await this.$store.dispatch("stopImpersonating");
          }
          await this.$store.dispatch("userProfile");
          await this.$store.dispatch("loadTerms");
          await this.$store.dispatch("loadSupports");
          await this.$store.dispatch("setCurrentTerm", this.user.current_term_id);
        }
        if (this.shouldSetupZendesk) {
          this.setupZendesk();
        }
        if (this.shouldSetupZonka) {
          this.setupZonka();
        }
        if (this.shouldSetupSentry) {
          this.setupSentry();
        }
        if (this.shouldSetupAccessibe) {
          this.setupAccessibe();
        }
        if (this.shouldSetupUserPilot) {
          this.setupUserPilot();
        }
        if (this.shouldSetupVitally) {
          this.setupVitally();
        }
        if (this.shouldSetupHotjar) {
          this.setupHotjar();
        }
      }
      finally {
        this.initialized = true;
      }
    },
    redirectOldDomain(url) {
      const allowedDomains = [
        "projectwayfinder.com", "withwayfinder.com"
      ];

      const urlParts = url.split("/");
      const hostname = urlParts[2];

      if (!allowedDomains.includes(hostname)) {
        return;
      }

      if (hostname === "projectwayfinder.com") {
        urlParts[2] = "withwayfinder.com";
        window.location = urlParts.join("/");
      }
    },
    setupZendesk() {
      const zendeskSrc = "https://static.zdassets.com/ekr/snippet.js?key=033fd35c-b47a-4497-b2f9-a7a59066d457";
      // NOTE: added this check so it won't get added twice
      if (document.querySelectorAll(`script[src="${zendeskSrc}"]`).length > 0) {
        return;
      }
      const recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute("id", "ze-snippet");
      recaptchaScript.setAttribute("src", zendeskSrc);
      document.head.appendChild(recaptchaScript);
    },
    setupZonka() {
      const district_id = this.user.district_id;
      const district_sf_id = this.user.district.sf_id;
      const zonkaScriptData = document.createElement("script");
      zonkaScriptData.append("window.zfEmbedSettings = { type: \"popup\",welcomescreen: \"false\",variant: \"large\",logo: \"false\",progress: \"false\",autoclose: \"true\",button_text: \"Feedback\", url: \"" + process.env.VUE_APP_ZONKA_SETTINGS_URL + "?usertype=" + this.activeRole + "&userid=" + this.user.id + "&districtid=" + district_id + "&districtsfid=" + district_sf_id + "\" }");
      const zonkaScript = document.createElement("script");
      zonkaScript.setAttribute("id", "zfEmbed");
      zonkaScript.append("(function() { ZonkaFeedback = function (en, fb ){document.body.addEventListener(en, fb , false); }; var sc, w, d = document, ce = d.createElement, gi = d.getElementById, gt = d.getElementsByTagName, id = \"zfEmbedScript\"; if (!gi.call(d, id)) { sc = ce.call(d, \"script\"); sc.id = id; sc.src = \"" + process.env.VUE_APP_ZONKA_URL + "?usertype=" + this.activeRole + "&districtid=" + district_id + "&districtsfid=" + district_sf_id + "\"; w = gt.call(d, \"script\")[0]; w.parentNode.insertBefore(sc, w); }})();");
      document.body.appendChild(zonkaScriptData);
      document.body.appendChild(zonkaScript);
    },
    setupAccessibe() {
      const accessibeScript = document.createElement("script");
      accessibeScript.append("(function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#146FF8', triggerColor : '#146FF8', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'bottom', triggerOffsetX : 100, triggerOffsetY : 35, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 10, triggerRadius : '20' } }); }; h.appendChild(s); })()");
      document.body.appendChild(accessibeScript);
    },
    setupUserPilot() {
      Userpilot.initialize(process.env.VUE_APP_USERPILOT_CODE);
      let properties = {
        demo: (this.user?.district?.demo_type && this.user.district.demo_type.length > 0),
        user_type: store.getters.activeRole,
        account_manager_id: this.user.district.account_manager_id
      };
      if (this.user && this.user.district_id) {
        properties["district_id"] = this.user.district_id;
      }
      if (this.user && this.user.district.name) {
        properties["district_name"] = this.user.district.name;
      }
      if (this.user && this.user.current_school_id && this.user.current_school_id.length > 0) {
        properties["school_id"] = this.user?.current_school_id;
        properties["school_name"] = this.user?.current_school?.name;
      }
      Userpilot.identify(this.user.id, properties);
    },
    setupHotjar() {
      const hotjarScriptData = document.createElement("script");
      hotjarScriptData.setAttribute("type", "text/javascript");
      hotjarScriptData.append("(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:" + process.env.VUE_APP_HOTJAR_ID + ",hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');");
      document.body.appendChild(hotjarScriptData);

      let hotjarProperties = {
        demo: (this.user?.district?.demo_type && this.user.district.demo_type.length > 0),
        user_type: store.getters.activeRole,
        account_manager_id: this.user.district.account_manager_id,
        environment: process.env.VIEW_APP_APP_ENV
      };
      if (this.user && this.user.district_id) {
        hotjarProperties["district_id"] = this.user.district_id;
      }
      if (this.user && this.user.district.name) {
        hotjarProperties["district_name"] = this.user.district.name;
      }
      if (this.user && this.user.current_school_id && this.user.current_school_id.length > 0) {
        hotjarProperties["school_id"] = this.user?.current_school_id;
        hotjarProperties["school_name"] = this.user?.current_school?.name;
      }
      window.hj("identify", this.user.id, hotjarProperties);
    },
    setupVitally() {
      const vitallyScript = document.createElement("script");
      vitallyScript.setAttribute("type", "text/javascript");
      vitallyScript.setAttribute("src", "https://cdn.vitally.io/vitally.js/v1/vitally.js");
      vitallyScript.setAttribute("defer", "");
      document.body.appendChild(vitallyScript);
      const vitallyScriptData = document.createElement("script");
      vitallyScriptData.setAttribute("type", "text/javascript");
      vitallyScriptData.append("!function(n,t,r){for(var i=n[t]=n[t]||[],o=function(r){i[r]=i[r]||function(){for(var n=[],t=0;t<arguments.length;t++)n[t]=arguments[t];return i.push([r,n])}},u=0,c=[\"init\",\"user\",\"account\",\"track\",\"nps\"];u<c.length;u++){o(c[u])}}(window,\"Vitally\");Vitally.init(\"" + process.env.VUE_APP_VITALLY_API_KEY + "\");");
      document.body.appendChild(vitallyScriptData);
      Vitally.account({
        accountId: this.user.district.sf_id,
        traits: {
          name: this.user.district.name,
          system_id: this.user.district_id
        }
      });
      Vitally.user({
        userId: this.user.id,
        accountId: this.user.district.sf_id,
        traits: {
          name: this.user.name,
          email: this.user.email,
          role: this.activeRole,
          district_id: this.user.district_id,
          district_name: this.user.district.name,
          district_sf_id: this.user.district.sf_id,
          school_id: this.user?.current_school?.id,
          school_name: this.user?.current_school?.name,
          createdAt: this.user.created_at
        }
      });
    },
    setupSentry() {
      try {
        Sentry.init({
          Vue: app,
          debug: false,
          dsn: process.env.VUE_APP_SENTRY_DSN,
          integrations: [
            new Integrations.BrowserTracing()
          ],
          ignoreErrors: [
            "ResizeObserver loop limit exceeded"
          ],
          tracesSampleRate: 0.75
        });
      }
      catch (error) {
        // ignore error for now.  Returns "undefined reading 'errorHandler'" deep in the Sentry repo code.
        // unclear why that will occur here but not in main.js
      }
    }
  }
});
</script>

<style lang="scss">
#app-content {
  @apply sm:pb-0;
}

.impersonate-user-outline {
  @apply fixed top-0 h-screen w-screen border-4 border-[#0099FF] border-solid z-[1000] pointer-events-none;
}

.impersonate-indicator {
  @apply fixed top-0 left-1/2 -translate-x-1/2 z-[1000] text-center text-white flex items-center gap-10;
}

.impersonate-user-name,
.impersonate-user-stop {
  @apply bg-[#0099FF] text-center rounded-b-8 py-6;
}

.impersonate-user-name {
  @apply px-20 text-14;
}

.impersonate-user-stop {
  @apply px-[14px];
}

.notification-bar {
  width: 100%;
  padding: 5px;
  color: #31708f;
  background: #d9edf7;
  position: absolute;
  box-sizing: border-box;
  z-index: 50;
  font-size: 14px;
  text-align: center;
}
</style>
